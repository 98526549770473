import { abdubelQueryApi } from "apis/QueryApi";
import { Endpoints, RtkqTagEnum } from "utils/constants";

export const userQueryApi = abdubelQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query({
      query: () => ({ url: Endpoints.MY_WALLET }),
      providesTags: [
        { type: RtkqTagEnum.AUTH_USER },
        { type: RtkqTagEnum.WALLET },
      ],
    }),
    createWallet: builder.mutation({
      query: (data) => ({
        url: Endpoints.CREATE_WALLET,
        data,
        method: "post",
      }),
      invalidatesTags: () => [
        { type: RtkqTagEnum.AUTH_USER },
        { type: RtkqTagEnum.WALLET },
        { type: RtkqTagEnum.TRANSACTION },
      ],
    }),
    generatePaymentLink: builder.mutation({
      query: (data) => ({
        url: Endpoints.GENERATE_PAYMENT_LINK,
        method: "post",
        data,
      }),
      invalidatesTags: () => [{ type: RtkqTagEnum.WALLET }],
    }),
    getProperties: builder.query({
      query: (params) => ({ url: Endpoints.PROPERTY, params }),
      providesTags: [{ type: RtkqTagEnum.PROPERTY }],
    }),
    getPropertyById: builder.query({
      query: (id) => ({ url: Endpoints.PROPERTY.concat(`/${id}`) }),
      providesTags: [{ type: RtkqTagEnum.PROPERTY }],
    }),
    invest: builder.mutation({
      query: (data) => ({
        url: Endpoints.INVEST,
        data,
        method: "post",
      }),
      invalidatesTags: () => [
        { type: RtkqTagEnum.WALLET },
        { type: RtkqTagEnum.INVESTMENTS },
        { type: RtkqTagEnum.AUTH_USER },
        { type: RtkqTagEnum.PROPERTY },
      ],
    }),
    getInvestments: builder.query({
      query: (params) => ({ url: Endpoints.INVESTMENTS, params }),
      providesTags: [{ type: RtkqTagEnum.INVESTMENTS }],
    }),
    getTransactions: builder.query({
      query: (params) => ({ url: Endpoints.TRANSACTION_HISTORY, params }),
      providesTags: [
        { type: RtkqTagEnum.TRANSACTION },
        { type: RtkqTagEnum.WALLET },
      ],
    }),
    getBankList: builder.query({
      query: (params) => ({ url: Endpoints.BANK_LIST, params }),
    }),
    performWithdrawal: builder.mutation({
      query: (data) => ({
        url: Endpoints.WITHDRAWAL,
        data,
        method: "post",
      }),
      invalidatesTags: () => [
        { type: RtkqTagEnum.WALLET },
        { type: RtkqTagEnum.INVESTMENTS },
        { type: RtkqTagEnum.AUTH_USER },
        { type: RtkqTagEnum.PROPERTY },
      ],
    }),
  }),
});
