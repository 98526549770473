import { Spin } from "antd";
import Modal from "./Modal";
import { LoadingOutlined } from "@ant-design/icons";

function LoadingBackdrop({ open }) {
  if (!open) return null;
  return (
    <>
      <Modal dismissable={false} onClose={() => {}}>
        <Spin
          size="large"
          indicator={
            <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
          }
          tip="Loading"
        />
      </Modal>
    </>
  );
}

export default LoadingBackdrop;
