import { createApi } from "@reduxjs/toolkit/query/react";
import Http from "./Http";
import { Endpoints, RtkqTagEnum } from "utils/constants";
import { objectToFormData } from "utils/Utils";

export const abdubelQueryApi = createApi({
  reducerPath: "abdubel",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({ url: Endpoints.LOGIN, data, method: "post" }),
    }),
    signup: builder.mutation({
      query: (data) => ({ url: Endpoints.SIGNUP, data, method: "post" }),
    }),
    refreshAccessToken: builder.mutation({
      query: (token) => ({
        url: "/refreshToken",
        data: { token },
        method: "post",
      }),
      // transformResponse: (data) => {
      //   const payload = jwt(data.accessToken);
      //   return {
      //     ...data,
      //     accessTokenExp: payload.exp,
      //   };
      // },
    }),
    validateRegistrationOtp: builder.mutation({
      query: (data) => ({
        url: Endpoints.VALIDATE_REGISTRATION_OTP,
        data,
        method: "PUT",
      }),
    }),
    requestPasswordReset: builder.mutation({
      query: (data) => ({
        url: Endpoints.REQUEST_PASSWORD_RESET,
        data,
        method: "put",
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: Endpoints.RESET_PASSWORD,
        data,
        method: "put",
      }),
    }),
    loadProfile: builder.mutation({
      query: () => ({ url: Endpoints.USER_PROFILE }),
    }),
    getUserProfile: builder.query({
      query: () => ({ url: Endpoints.USER_PROFILE }),
      providesTags: () => [{ type: RtkqTagEnum.AUTH_USER }],
    }),
    updateProfile: builder.mutation({
      query: ({ payload, params }) => ({
        url: Endpoints.UPDATE_PROFILE,
        params,
        data: payload,
        method: "put",
      }),
      invalidatesTags: () => [{ type: RtkqTagEnum.AUTH_USER }],
    }),
    updatePassword: builder.mutation({
      query: (data) => ({
        url: Endpoints.UPDATE_PASSWORD,
        data,
        method: "put",
      }),
      invalidatesTags: () => [{ type: RtkqTagEnum.AUTH_USER }],
    }),
    createFeedback: builder.mutation({
      query: (data) => ({
        url: Endpoints.CREATE_FEEDBACK,
        data,
        method: "post",
      }),
      invalidatesTags: () => [{ type: RtkqTagEnum.FEEDBACK }],
    }),
    uploadProfilePicture: builder.mutation({
      query: (data) => ({
        url: Endpoints.UPLOAD_PROFILE_PICTURE,
        method: "put",
        data: objectToFormData(data),
      }),
      invalidatesTags: [{ type: RtkqTagEnum.AUTH_USER }],
    }),
    getMyPortfolio: builder.query({
      query: (params) => ({ url: Endpoints.PORTFOLIO, params }),
      providesTags: () => [{ type: RtkqTagEnum.AUTH_USER }],
    }),
  }),
});

[abdubelQueryApi].forEach((api) => {
  api.enhanceEndpoints({ addTagTypes: Object.values(RtkqTagEnum) });
});

/**
 *
 * @param {import("axios").AxiosRequestConfig} baseConfig
 */
export function axiosBaseQuery(baseConfig = {}) {
  return request;

  /**
   *
   * @param {import("axios").AxiosRequestConfig} config
   */
  async function request(config = {}) {
    const url = config.url
      ? (baseConfig.url || "") + config.url
      : baseConfig.url;
    try {
      const response = await Http.request({ ...baseConfig, ...config, url });

      return {
        ...response,
        data: response.data,
        message: response.data?.message || null,
        status: response.status || 200,
        meta: { request: response.request, response },
      };
    } catch (error) {
      return {
        error: error.response
          ? {
              message: "",
              status: error.response.status,
              data: error.response.data,
            }
          : {
              message: "Something went wrong",
              data: { message: "Something went wrong" },
            },
      };
    }
  }
}

export function providesTags(resultsWithIds, tagType, options = {}) {
  const { selectId = ({ id }) => id } = options;
  const listTag = { type: tagType, id: "LIST" };
  const result = resultsWithIds
    ? [
        listTag,
        ...resultsWithIds.map((result) => ({
          type: tagType,
          id: selectId(result),
        })),
      ]
    : [listTag];

  return result;
}

export function invalidatesTags(tagType, options = {}) {
  const { ids = [] } = options;
  const result = [
    { type: tagType, id: "LIST" },
    ...ids.map((id) => ({ type: tagType, id })),
  ];

  return result;
}
