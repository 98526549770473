import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

const Modal = ({ onClose, children, dismissable }) => {
  const [modalElement] = useState(document.createElement("div"));
  const overlayClickHandler = dismissable ? onClose : undefined;

  useEffect(() => {
    modalRoot.appendChild(modalElement);

    return () => {
      modalRoot.removeChild(modalElement);
    };
  }, [modalElement]);

  return ReactDOM.createPortal(
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full">
      <div
        className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
        onClick={overlayClickHandler}
      ></div>
      <div className="z-10 flex justify-center w-full p-8 ">{children}</div>
    </div>,
    modalElement
  );
};

export default Modal;
