import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "store/StoreActions";
import { abdubelQueryApi } from "apis/QueryApi";
import { userQueryApi } from "apis/UserQueryApi";

export const globalInitialState = {
  authUser: null,
  accessToken: null,
  refreshToken: null,
  walletInformation: null,
  isLoadingModal: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleLoadingModalAction: (state, { payload }) => {
      state.isLoadingModal =
        payload !== undefined ? !!payload : !state.isLoadingModal;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction, () => ({ ...globalInitialState }))
      .addMatcher(
        abdubelQueryApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.accessToken = payload.access_token;
          state.refreshToken = payload.refresh_token;
          state.authUser = {
            ...payload?.profile,
          };
        }
      )
      .addMatcher(
        abdubelQueryApi.endpoints.validateRegistrationOtp.matchFulfilled,
        (state, { payload }) => {
          state.accessToken = payload.access_token;
        }
      )
      // .addMatcher(
      //   abdubelQueryApi.endpoints.loadProfile.matchFulfilled,
      //   (state, { payload }) => {
      //     // state.accessToken = payload.access_token;
      //     state.authUser = {
      //       ...state.authUser,
      //       profile: payload.profile,
      //     };
      //   }
      // )
      .addMatcher(
        abdubelQueryApi.endpoints.getUserProfile.matchFulfilled,
        (state, { payload }) => {
          state.authUser = {
            ...state?.authUser,
            ...payload?.profile,
          };
        }
      )
      .addMatcher(
        userQueryApi.endpoints.getWallet.matchFulfilled,
        (state, { payload }) => {
          state.walletInformation = payload?.data;
        }
      ),
});

export const { toggleLoadingModalAction } = globalSlice.actions;
export default globalSlice;

export function getGlobalSliceStorageState({
  authUser,
  accessToken,
  refreshToken,
  walletInformation,
} = {}) {
  return { authUser, accessToken, refreshToken, walletInformation };
}
