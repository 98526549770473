import React, { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Router from "./router/Router";
import "./App.css";
import LoadingBackdrop from "components/LoadingBackdrop";
import { Spin } from "antd";
import useLoadingModal from "hooks/useLoadingModal";

function App() {
  const { isLoadingModal } = useLoadingModal();
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <BrowserRouter>
        <LoadingBackdrop open={isLoadingModal} />
        <Suspense
          fallback={
            <Spin
              spinning={true}
              fullscreen
              className="flex items-center justify-center h-screen"
            />
          }
        >
          <Router />
        </Suspense>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
